/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background: #B3B2E3;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px #232135;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #675AFF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #6253e8;
}

body {
	margin: 0;
	padding-right: 0;
	color: #343E4E;
	font-family: 'Blinker', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	scrollbar-color: #6253e8 #232135;
	scrollbar-width: thin;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent; /* Removes tap highlight color */
	outline: none; /* Removes the outline */
}

a:focus, a:active {
	outline: none; /* Removes focus outline when tapping */
}
