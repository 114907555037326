:root {
	--gradientLightBlue: linear-gradient(to right, #209eff, #4148ff) border-box;

	/*--gradientLightBlue: linear-gradient(to bottom right, #00ffea, #0099ff);*/
}

.about-wrapper {
	padding: 0 30px;
	padding-bottom: 50px;
	max-width: var(--container-width);
	margin: 0 auto;
}

.about {
	background: #F9FBFF;
	padding-top: 5rem;
}

.about h2 {
	background: var(--gradientLightBlue);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
}

.profile {
	display: flex;
	flex-direction: row;
}

.picture {
	display: flex;
	flex-direction: column;
	color: #8b8ad2;
	font-size: calc(0.3vw + 0.7rem);
	padding-bottom: 40px;
}

.picture .info-outline {
	background: linear-gradient(white, white) padding-box,
	var(--gradientBlue) border-box;
	border-radius: 40px;
	padding: 12px 35px;
 	border: 4px solid transparent;
}

.picture .info {
	background: white;
	margin: 0 auto;
	margin-top: -35px;
	border-radius: 40px;
	border: 6px solid white;
}
.picture .info span {
	background: var(--gradientBlue);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
	font-size: calc(0.3vw + 1rem);
	font-weight: 600;
}

.profile h3 {
	margin: 0;
	padding-bottom: 10px;
	margin-left: -20px;
	font-size: calc(0.2vw + 1rem);
	font-weight: 600;
	background: var(--gradientLightBlue);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
	text-align: left;
}

.quotes {
	margin-left: 80px;
}

.quote {
	padding-top: 30px;
}

.quote:first-of-type {
	padding-top: 0;
}
 
.quote span { 
	display: flex;
	align-items: center;
	text-align: left;
	font-size: calc(0.3vw + 0.7rem);
	background: white;
	border-radius: 40px;
	padding: 15px 30px;
}

.quote span img {
	margin-bottom: -6px;
	padding-right: 15px;
	margin-left: -58px;
}

@media only screen and (max-width: 900px) {
	.profile {
		flex-direction: column;
	}
	
	.picture-container {
		box-sizing: border-box;
		width: 100%;
	}

	.picture-container img {
		max-width: 100%;
		height: auto;
		display: block;
	}
}

@media only screen and (max-width: 600px) {
	.profile {
		flex-direction: column;
	}
	
	.picture-container {
		box-sizing: border-box;
		padding: 0 40px;
		width: 100%;
	}
	
	.picture-container img {
		max-width: 100%;
		height: auto;
		display: block;
	}

	.quotes {
		margin: 0 30px;
	}
	
	.profile h3 {
		text-align: center;
	}

	.quote span img {
		width: 40px;
		margin-left: -50px;
	}
}