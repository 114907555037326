:root {
	--description-margin: 35px;
	--button-height: calc(1.8vw + 1.8rem);
}

.popup-pages {
	overflow: hidden; /* This will clip/mask the content when transitioning */
	outline: none; /* Remove the focus outline when the div is focused */
	height: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
	align-content: center;
	align-items: center;
}

.popup-pages > div {
	height: 100%;
}

.pages-container {
	display: flex;
	max-width: 80vw;
	transition: transform 0.5s ease; /* Smooth transition for sliding */
	height: calc(100% - var(--button-height));
}

.page {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	min-width: 100%; /* Each page takes full width of the container */
	align-content: center;
	/*flex-shrink: 0; !* Prevent flexbox from shrinking the pages *!*/
}

.page > div {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 0.2rem;
}

.page-content {
	text-align: center;
	/*min-height: calc(var(--popup-height) - var(--popup-margin) - var(--content-padding));*/
}

.page-content img {
	max-height: 50vh;
	max-width: 100%;
	border-radius: calc(1.6vw + 0.4rem);
}

.page-content-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 55vh;
	width: 100%;
}

.page-content-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	place-items: center;
	place-content: center;
}

.page-content-row img {
	max-width: calc(8vw + 6rem);
	border-radius: calc(0.7vw + 0.5rem);
	margin-right: calc(1vw + 0.5rem);
}

.page-content-row img:last-of-type {
	margin-right: 0;
}

.page-description-container {
	display: flex;
	justify-content: center;
}

.page-description {
	max-width: 100%;
	background: #eeeeff;
	border-radius: calc(0.1vw + 0.6rem);
	border: calc(0.07vw + 0.09rem) solid #a2aeff;
	margin: var(--description-margin) 0;
	padding: calc(0.4vw + 0.3rem) calc(0.6vw + 0.8rem);
}

.page-description p {
	margin: 0;
	padding: 0;
	padding-top: calc(0.3vw + 0.2rem);
	font-size: calc(0.6vw + 0.7rem);
	text-align: center;
	color: #615cff;
}

.page-description p:first-of-type {
	padding-top: 0;
}

.nav-button {
	background: rgba(0, 0, 0, 0.5);
	border: none;
	color: white;
	width: var(--button-height);
	height: var(--button-height);
	border-radius: calc(0.45vw + 0.5rem);
	cursor: pointer;
	z-index: 10;
	background: #8B59FF;
}

.nav-button.left {
	/*left: var(--content-padding);*/
	/*bottom: var(--content-padding);*/
}

.nav-button.left svg {
	width: calc(0.4vw + 0.4rem);
	height: auto;
	padding-top: 3px;
	padding-right: 3px;
}

.nav-button.right {
	/*right: var(--content-padding);*/
	/*bottom: var(--content-padding);*/
}

.nav-button.right svg {
	width: calc(0.4vw + 0.4rem);
	height: auto;
	padding-top: 3px;
	padding-left: 3px;
}

.page-counter {
	/*height: var(--popup-footer-height);*/
	text-align: center;
	align-content: center;
	color: #8B59FF;
	font-size: calc(1vw + 1rem);
	font-weight: 600;
	z-index: 10;
	height: var(--button-height);
}