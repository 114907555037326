:root {
	--gradient: linear-gradient(to bottom right, #22CAFF, #3E7FFF);
	--gradientBlue: linear-gradient(to bottom right, #1DAEFF, #433FFF);
	--gradientPink: linear-gradient(to bottom right, #FF6CC4, #FF4466);
	--gradientPurple: linear-gradient(to bottom right, #9757FF, #713FFF);
	--gradientPurpleLight: linear-gradient(to bottom right, #cb6bff, #ac46e3);
}

.home {
	background: #F9FBFF;
	padding-top: 10rem;
}

.home-picture {
	padding-bottom: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.border {
	border-radius: 50%;
	border: 8px solid #fff;
	box-shadow: 0 0 0 8px #5584FF;
}

.status-wrapper {
	position: absolute;
}

.status {
	background: white;
	display: flex;
	align-items: center;
	border-radius: 40px;
	padding: 10px 25px 10px 20px;
	font-size: calc(1vw + 0.6rem);
	font-weight: 600;
	margin-top: 1.6rem;
	margin-left: 27rem;
	height: 44px;
}

.status img {
	padding-right: 15px;
}

.status:first-of-type {
	margin-top: 5rem;
	margin-left: 29rem;
}

.status span {
	font-weight: 400;
	font-size: calc(0.7vw + 0.6rem);
	padding-top: 5px;
	padding-left: 10px;
}

.home-wrapper h1 {
	font-size: calc(4vw + 2rem);
	font-weight: 600;
	height: auto;
	margin-top: 0;
}

.typewriter-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: calc(1.6vw + 1.2rem);
	font-weight: 400;
}

.typewriter-cursor {
	animation: blink 1s step-end infinite;
	-webkit-animation: blink 1s step-end infinite;
	-z-animation: blink 1s step-end infinite;
	-o-animation: blink 1s step-end infinite;
}

@keyframes blink {
	0%,100%{color: white;}
	50%{color: #343E4E;}
}

@-webkit-keyframes blink {
	0%,100%{color: white;}
	50%{color: #343E4E;}
}
@-moz-keyframes blink {
	0%,100%{color: white;}
	50%{color: #343E4E;}
}
@-o-keyframes blink {
	0%,100%{color: white;}
	50%{color: #343E4E;}
}

.home-wrapper h1 .gradientText {
	background: var(--gradientBlue);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
}

@media only screen and (max-width: 1040px) and (min-width: 899px) {
	.home {
		padding-top: 8rem;
	}
}

@media only screen and (max-width: 900px) and (min-width: 800px) {
	.home {
		padding-top: 8rem;
	}
	
	.status {
		position: relative;
	}
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
	.home {
		padding-top: 8rem;
	}
}

@media only screen and (max-width: 600px) {
	.home {
		padding-top: 6rem;
	}
	
	.border {
		width:40%;
		border: 7px solid #fff;
		box-shadow: 0 0 0 7px #5584FF;
	}

	.status-wrapper {
		display: none;
	}
}
