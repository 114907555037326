:root {
	--popup-margin: 200px;
	--popup-width: 1050px;
	--popup-height: 800px;
	--popup-header-height: calc(2vw + 2rem);
	--popup-footer-height: 54px;
	--popup-border-radius: calc(1.2vw + 2rem);
	--border-thickness: 4px;
	--content-padding: calc(1.2vw + 0.5rem);
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 6, 36, 0.82);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	overflow: hidden;
}

.popup-margin {
	margin: calc(1vw + 0.5rem);
}

.popup-wrapper-wrapper {
	display: flex;
	background: white;
	border-radius: var(--popup-border-radius);
	/*min-height: var(--popup-height);*/
	min-width: calc(40vw + 10rem);
	min-height: calc(25vw + 20rem);
	max-width: 50vw;
	max-height: 90vh;
	box-shadow: 0 0 50px 15px rgba(186, 156, 255, 0.25); /* Add drop shadow */
}

.popup-wrapper {
	width:100%;
	display: flex;
	border-radius: calc(var(--popup-border-radius) - var(--border-thickness));
	margin: var(--border-thickness);
	background: linear-gradient(135deg, #66A3FF, #8F27FE);
}

.popup {
	width:100%;
	display: flex;
	background: white;
	/*min-width: calc(var(--popup-width) - var(--popup-margin) - var(--content-padding));*/
	/*max-width: 90vw;*/
	/*max-height: 90vh;*/
	margin: var(--border-thickness);
	border-radius: calc(var(--popup-border-radius) - var(--border-thickness) * 2);
	position: relative;
	padding: var(--content-padding);
}

.popup-content {
	display: flex;
	flex-direction: column;
	width:100%;
	height:100%;
}

.popup-content > div {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.popup-content div {
	outline: none;
}

.popup-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: calc(1vw + 0.2rem);
	height: var(--popup-header-height);
	/*margin-top: -15px;*/
}

.popup-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.popup h1 {
	border-radius: 50px;
	color: #7657FF;
	padding: calc(0.1vw + 0.13rem); /* border thickness */
	margin: 0;
	background: linear-gradient(45deg, #A37BFF, #5B7CFF);
	margin-right: 0.5rem;
}

.popup h1 span {
	display: block;
	border-radius: 50px;
	color: #7657FF;
	padding: 0 calc(1vw + 1rem);
	background: white;
	height: 100%;
	align-content: center;
}

.popup h1 span .title {
	font-size: calc(1.7vw + 1.1rem);
	font-weight: 600;
	padding: 0;
	border-radius: 0;
	background: linear-gradient(45deg, #6560FF, #B351FF);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
}

.popup h2 {
	padding: 0;
	margin: 0;
	font-size: calc(1.5vw + 0.55rem);
	color: #6579fc;
	font-weight: 600;
	padding-left: 1vw;
	/*width: 100%;*/
}

.popup-platform {
	/*width: 100%;*/
	height: 100%;
	text-align: right;
}

.popup-platform svg {
	max-width: calc(1vw + 1.3rem);
	/*max-height: calc(1.2vw + 1.1rem);*/
	height: 100%;
	/*width: 100%;*/
	/*height: calc(0.7vw + 0.5rem);*/
	/*width: calc(5vw + 0.5rem);*/
	padding-right: calc(1vw + 0.2rem);
	fill: #94afff;
}

.popup-platform svg:last-of-type {
	padding-right: 0;
}

.store-links {
	position: absolute;
	display: flex;
	flex-direction: row;
	width: 100%;
	place-content: center;
	/*margin-top: calc(-4vw + -1.5em);*/
	/*margin-bottom: calc(2vw + 0.75em);*/
}

.store-links a {
	text-decoration: none;
}

.store-link:first-of-type {
	margin-left: 0;
}

.store-link {
	margin-top: calc(-6vw + -2rem);
	border-radius: calc(0.1vw + 0.3rem);
	background: var(--gradientPurple);
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	height: calc(1vw + 1rem);
	width: auto;
	margin-left: calc(0.6vw + 0.6rem) !important;
	padding: 0 calc(0.8vw + 0.3rem);
	filter: drop-shadow(0px 8px 7px rgba(18, 7, 50, 0.2));
	/*border: calc(0.08vw + 0.07rem) white solid;*/
}

.store-link span {
	padding-right: 0.3rem;
	font-size: calc(0.7vw + 0.3rem);
	font-weight: 400;
	line-height: calc(0.5vw);
}

.app-store {
	background: var(--gradientPurpleLight);
}

.app-store svg {
	height: calc(0.7vw + 0.5rem);
	width: calc(0.7vw + 0.5rem);
	padding-bottom: 0.1rem;
}

.google-play {
	background: var(--gradientPurple);
}

.google-play svg {
	height: calc(0.6vw + 0.4rem);
	width: calc(0.6vw + 0.4rem);
	padding-left: 0.1rem;
}

.video-container {
	width: 100%;
	max-width: calc(var(--popup-width) - var(--content-padding) - var(--popup-margin));
	margin: 0 auto;
	position: relative;
	aspect-ratio: 16 / 9;
	overflow: hidden;
	border-radius: 20px;
}

.video {
	/*background-color: #6186cf;*/
	background: var(--popup-video-background);
	object-fit: cover; /* This will crop the video to fit within the container */
	object-position: center center; /* Adjust this to change the cropping position */
	width: 100%;
	height: 100%;
	display: block;
	cursor: pointer;
}

.icon-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #5d6dfd;
	border-radius: 50%;
	padding: 10px;
	cursor: pointer;
	width: calc(3vh + 0.5rem);
	height: calc(3vh + 0.5rem);
	padding: calc(1.5vh + 0.5rem);
	transition: opacity 0.3s ease; /* Smooth fade transition */
	border: calc(0.1vw + 0.2rem) solid white;
}

.play-pause-icon {
	color: white;
	font-size: 24px;
	width: 100%;
	height: 100%;
}

.play-icon {
	padding-left: calc(0.15vw + 0.15rem);
}

.pause-icon {
	padding-left: 0;
}

.popup-footer {
	display: flex;
	height: var(--button-height);
	justify-content: space-between;
}

.no-scroll {
	overflow: hidden;
	height: 100vh; /* Prevent scrolling by restricting height */
}

.close-btn {
	position: absolute;
	top: calc(-0.8vw + -1.3rem);
	right: calc(-0.8vw + -1.3rem);
	background: none;
	border: none;
	cursor: pointer;
}

.close-btn img {
	width: calc(6.5vh + 0.5rem);
}

.icon-container.visible {
	opacity: 1;
}

.icon-container.hidden {
	opacity: 0;
}

.slideUp {
	animation: slideUp 0.3s forwards;
}

.slideDown {
	animation: slideDown 0.3s forwards;
}

.fadeIn {
	animation: fadeIn 0.3s forwards;
}

.fadeOut {
	animation: fadeOut 0.3s forwards;
}


@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(20%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes slideDown {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}

@media only screen and (max-width: 1080px) {
	.popup-wrapper-wrapper {
		max-width: 60vw;
	}
	.store-link span {
		font-weight: 600;
	}
}

@media only screen and (max-width: 600px) {
	.popup-wrapper-wrapper {
		max-width: 100vw;
	}
	.close-btn {
		top: 0;
		right: 0;
		left: 0;
		bottom: -105%;
	}
	.close-btn img {
		width: 50px;
	}
}
