:root {
	--skill-color: #B3B2E3;
	--container-width: 1200px;
}

.skills {
	display: flex;
	flex-direction: column;
	padding-top: 5rem;
}

.skills h2 {
	background: var(--pink-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
}

.skills-small, .skills-big {
	max-width: var(--container-width);
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.skills-small div, .skills-big div {
	display: grid;
	place-items: center;
}

.skills-big div {
	min-height: 180px;
	width: 180px;
}

.skills-small div {
	min-height: 140px;
	width: 140px;
}

.skills-small p, .skills-big p {
	color: var(--skill-color);
	font-weight: 600;
	font-size: calc(0.3vw + 0.7rem);
	margin-top: -5px;
}

.skills-small p {
	margin-top: -15px;
}

.skills-small div svg, .skills-big div svg {
	color: var(--skill-color);
	fill: var(--skill-color);
	transition: all 390ms ease-in-out;
}

.skills-big div svg {
	min-height: 100px;
	width: 100px;
}

.skills-small div svg {
	min-height: 60px;
	width: 60px;
}

.skills-small div:hover, .skills-big div:hover {
	--skill-color: #FF49A1;
}

@media only screen and (max-width: 600px) {
	:root {
		--container-width: 500px;
	}

	.skills-big div {
		min-height: 90px;
		width: 90px;
	}

	.skills-big div svg {
		min-height: 50px;
		width: 50px;
	}

	.skills-small div {
		min-height: 70px;
		width: 70px;
	}

	.skills-small div svg {
		min-height: 35px;
		width: 35px;
	}

	.skills-small p {
		margin-top: 5px;
	}
}
@media only screen and (max-width: 500px) {
	:root {
		--container-width: 380px;
	}
}
@media only screen and (max-width: 350px) {
	:root {
		--container-width: 300px;
	}
}
