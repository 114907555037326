/* GifSwitcher.css */
.gif-container {
    position: relative;
    /*height: calc(10vh); !* Adjust as needed *!*/
    margin: calc(0.4vw + 0.4rem) 0;
    display: flex;
}

.gif-image {
    display: flex;
    width: 100%;
    display: flex;
    border-radius: 20px !important;
    /*object-fit: cover; !* Ensure the GIF fits the container *!*/
}

.page-content-column .gif-container img {
    max-height: calc(11vh + 1.5rem);
    width: auto;
    max-width: 100%;
}

.gif-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(121, 94, 255, 0.93);
    pointer-events: none;
    transition: opacity 0.3s ease;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gif-overlay p {
    font-size: 1.25rem;
    color: white;
    margin-top: calc(-1.4vw + -1.4rem);
}

.gif-overlay-icon {
    color: white;
    width: calc(1.2vw + 1.7rem);
    height: 100%;
    max-width: 35px;
}

.gif-container:hover .gif-overlay,
gif-container:hover .gif-overlay img  {
    opacity: 0; /* Hide the overlay when hovered */
}
