.header {
	position: fixed;
	min-width: 100%;
	justify-content: center;
	padding: 0;
	margin: 0 auto;
	z-index: 101;
	display: flex;
	background-color: rgba(255, 255, 255, 0.97);
}

.header {
	transition: all 1s;
  }

.nav ul {
	display: flex;
	align-content: space-between;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 50px;
	padding-inline-start: 0;
	margin: 0;
	padding: 0;
}

.nav ul li, .nav ul li a{
	list-style: none;
	font-size: calc(1.6vh + 0.1rem);
	font-weight: 600;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0 calc(1vw + 0.1rem);
}

.nav ul li:hover,
.nav ul li:active {
	cursor: pointer;
	border-image-source: linear-gradient(to right, #6253e8, #1da1f2);
	color: #205EFF;
}

.nav ul li .active {
	color: #205EFF;
	border-bottom: 2px solid #5584FF;
}

@media only screen and (max-width: 600px) {
	/*.nav ul li, .nav ul li a{*/
	/*	font-size: 0.7rem;*/
	/*	width: 78px;*/
	/*}*/
}