:root {
	--project-color: #B3B2E3;
	--project-width: 324px;
	--border-radius: 10px;
	--popup-video-background: linear-gradient(-45deg, rgba(52, 54, 136, 0.95) 0%, rgba(109, 83, 169, 0.95) 100%);
	--video-background: linear-gradient(-45deg, rgba(249, 246, 255, 0.9) 0%, rgba(241, 241, 255, 0.8) 100%);
}

.projects {
	padding-top: 5rem;
}

.projects h2 {
	background: var(--purple-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
	font-size: calc(3vw + 1.65rem);

}

.projects > div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.project {
	min-width: var(--project-width);
	max-width: var(--project-width);
	border-radius: var(--border-radius);
	margin: 2rem 5rem;
	position: relative;
	background-color: #fff;
}

.project h3 {
	font-size: 1.8vw;
	font-size: calc(1vw + 1rem);
	font-weight: 600;
	color: white;
	background: linear-gradient(to right, #9757FF, #aa60ff) border-box;
	padding: 8px 50px;
	margin: 0 -20px;
	z-index: 200;
	position: relative;
	align-content: center;
	text-align: center;
	display: inline-block;
	border: 5px solid white;
	top: -40px;
	border-radius: 40px;
}

.preview {
	display: grid;
}

.preview-background {
	background: linear-gradient(to bottom right, #54b4ff, #9757FF) border-box;
	mask-image: url('../images/project-thumb-mask-outline.svg');
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: 100%;
	-webkit-mask-image: url('../images/project-thumb-mask-outline.svg');
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center;
	-webkit-mask-size: 100%;
	grid-area: 1 / 1;
}

.preview-overlay {
	width: 100%;
	height: 100%;
	display: grid;
	align-content: center;
	justify-content: center;
	background: var(--video-background);
}

.video_holder {
	/*width: 298px;*/
	/*height: 298px;*/
	margin: 13px;
	mask-image: url('../images/project-thumb-mask.svg');
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: 100%;
	-webkit-mask-image: url('../images/project-thumb-mask.svg');
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center;
	-webkit-mask-size: 100%;
	grid-area: 1 / 1;
	justify-content: center;
	align-items: center;
	display: flex;
}

.video_holder_video {
	position: relative;
	/*rotate: -14.3deg;*/
}

.preview h3 {
	font-size: calc(0.1vw + 1rem);
	font-weight: 400;
	color: white;
	background: linear-gradient(to right, #745bff, #6068ff) border-box;
	padding: 5px 20px;
	z-index: 200;
	text-align: left;
	white-space: nowrap;
	border: 3px solid white;
	position: absolute;
	top: 20px;
	left: 230px;
}

.project > p {
	margin-top: -1rem;
	margin-bottom: 1.2rem;
	color: #7285A1;
}

.project > p a {
	font-weight: 600;
	color: #9757FF;
}

.project-button {
	font-size: calc(0.2vw + 0.9rem);
	color: white;
	background: linear-gradient(to right, #745bff, #6068ff) border-box;
	padding: 0.6rem 1rem;
	font-weight: 600;
	position: relative;
	text-align: center;
	border-radius: calc(0.2vw + 0.3rem);
	margin-bottom: 1.5rem;
}

.show {
	display: inline-block;
}

.hide {
	display: none;
}

.software {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
}

.software div {
	width: 80px;
	margin-bottom: calc(10vh - 4vw);
}

.software div svg, .software div img {
	min-height: calc(var(--project-width) - 290px);
	width: calc(var(--project-width) - 290px);
	color: var(--project-color);
	fill: var(--project-color);
	/*transition: all 390ms ease-in-out;*/
	margin-bottom: -8px;
}

.software div:hover {
	--project-color: #9d94d4;
}

.software p {
	color: var(--project-color);
	font-weight: 600;
	font-size: calc(0.3vw + 0.5rem);
	margin-top: 10px;
}

.other-projects {
	display: flex;
	font-weight: 800;
	font-size: calc(0.7vw + 0.5rem);
	color: #606283;
	align-items: center;
}

.other-projects a {
	font-size: calc(0.7vw + 0.5rem);
	font-weight: 600;
	padding-left: calc(0.5vw + 0.5rem);
	padding-right: calc(0.5vw + 0.5rem);
	color: #6068ff;
}

.other-projects a span {
	font-weight: 400;
	color: #606283;
}

@media screen and (max-width: 600px) {
	.project {
		margin: 0;
	}
	
	.project h3 {
		font-size: calc(1vw + 2rem);
	}
	
	.preview h3 {
		display: none;
	}
	
	.software div {
		width: 60px;
	}
}