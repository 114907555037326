:root {
	--input-width: 1000px;
	--input-padding: 15px;
}

.mail {
	padding-top: 5rem;
}

.mail h2 {
	background: var(--purple-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
}

.mail h2 span {
	margin-top: 30px;
}

.outro {
	background: rgb(218,236,245);
	background: linear-gradient(0deg, rgba(218,236,245,1) 0%, rgba(218,236,245,0.5) 10%, rgba(218,236,245,0) 40%);
	margin-top: 320px;
	padding-bottom: 80px;
}

.outro h2 {
	background: var(--gradientBlue);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	color: transparent;
	margin-bottom: 0;
}

.outro h3 {
	color: #3b5794;
	font-size: calc(0.3vw + 0.8rem);
	font-weight: 400;
	margin: 0;
}

.outro .btn-wrapper {
	margin-top: 60px;
}

.contact-info {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.contact-info div {
	padding: 0 30px;
}

.contact-info img {
	margin-right: -40px;
	position: relative;
	transform: rotate(-15deg);
}

.contact-info span {
	background: #F9FBFF;
	border-radius: 40px;
	display: inline-block;
	padding: 15px 50px 35px 60px;
	font-weight: 600;
	font-size: calc(0.6vw + 1.1rem);
}

.contact-info p {
	margin-bottom: -60px;
	color: #945AFF;
	font-size: calc(0.3vw + 0.7rem);
}

.entry {
	text-align: left;
	margin: 0 auto;
	width: calc(var(--input-width) - (var(--input-padding) * 2));
}

.entry label {
	color: #945AFF;
	font-weight: 600;
	font-size: 1vw;
}

.entry input,
.entry textarea {
	background-color: #F9FBFF;
	border: 2px solid #FFF;
	border-radius: 10px;
	caret-color: #A08ED3;
	color: #3B5794;
	transition: all 0.3s;
	padding: var(--input-padding);
	resize: none;
	outline: none;
	font-size: 1vw;
	font-family: inherit;
}

.entry textarea::placeholder, .entry input::placeholder {
	color: #A08ED3;
}

.entry input:active,
.entry textarea:active,
.entry input:focus,
.entry textarea:focus {
	border: 2px solid #945AFF;
	color: #3B5794;
}

.entry > div:not(.mail-btn, .options) {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.entry > div:not(.mail-btn) label {
	margin-bottom: 5px;
}

.mail-btn {
	cursor: pointer;
	background-color: #945AFF;
	padding: 10px 0;
	display: grid;
	place-items: center;
	border-radius: 10px;
	margin-top: 10px;
	color: #fff;
	font-weight: 400;
	font-size: 1.4vw;
}

@media only screen and (max-width: 600px) {
	.contact-info {
		flex-direction: column;
	}

	.contact-info img {
		margin-right: -10px;
		margin-bottom: 10px;
		width: 50px;
	}

	.contact-info span {
		background: #F9FBFF;
		border-radius: 40px;
		display: inline-block;
		padding: 10px 20px 35px 25px;
		font-weight: 600;
		font-size: calc(0.6vw + 1.1rem);
	}

	.contact-info div {
		padding-bottom: 30px;
	}
}
