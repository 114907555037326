:root {
	--purple-gradient: linear-gradient(to bottom right, #9757FF, #713FFF);
	--pink-gradient: linear-gradient(to bottom right, #FF218C, #FF3062);
	--orange-gradient: linear-gradient(to bottom right, #FFA621, #ff6030);
	--green-gradient: linear-gradient(to bottom right, #0bc614, #009b4a);
	--btn-gradient: linear-gradient(to bottom right, #4B7EFF, #595CFF);
	--btn-cv-gradient: linear-gradient(to bottom right, #FF218C, #FF3062);
}

.App > div:first-of-type {
	border-top: none;
}

.App {
	color: #343E4E;
	/*transition: all 0.5s;*/
	text-align: center;
	box-shadow: none;
}

.App a {
	text-decoration: none;
}

.App h2 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: calc(2vw + 2.5rem);
	font-weight: 600;
	margin: calc(3vw + 1rem) auto;
}

.App h2 span {
	color: #3b5794;
	-webkit-text-fill-color: #3b5794;
	font-size: calc(1vw + 1.2rem);
	margin: calc(-2vw) auto;
}

.btn-wrapper {
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 10vw;
	color: #3b5794;
}

.btn {
	cursor: pointer;
	display: flex;
	place-items: center;
	font-weight: 600;
	font-size: calc(0.5vw + 0.8rem);
	text-transform: uppercase;
	background: var(--btn-gradient);
	color: white;
	border-radius: 40px;
	padding: 7px 20px;
}

.cv {
	margin-left: 20px;
	background: var(--btn-cv-gradient);
}

.btn span {
	padding-left: 10px; 
}

.btn svg {
	width: 10px;
	padding-bottom: -2px;
	padding-left: 10px;
	fill: white;
	animation: float 0.4s ease-in-out infinite;
	-webkit-animation: float 0.4s ease-in-out infinite;
	-z-animation: float 0.4s ease-in-out infinite;
	-o-animation: float 0.4s ease-in-out infinite;
}

.cv svg {
	animation: none;
}

.endsection-gradient {
	height: 6vw;
	background: rgb(145,128,255);
	background: linear-gradient(180deg, rgba(145,128,255,0) 0%, rgba(145,128,255,0.02) 22%, rgba(145,128,255,0.06) 40%, rgba(145,128,255,0.2) 100%);
}

.endsection {
	height: 122px;
}

@keyframes float {
	0%,100%{transform: translateY(0);}
	50%{transform: translateY(2px);}
}

@-webkit-keyframes float {
	0%,100%{transform: translateY(0);}
	50%{transform: translateY(2px);}
}
@-moz-keyframes float {
	0%,100%{transform: translateY(0);}
	50%{transform: translateY(2px);}
}
@-o-keyframes float {
	0%,100%{transform: translateY(0);}
	50%{transform: translateY(2px);}
}

.arrow {
	background-color: #232135;
	height: 60px;
	width: 60px;
	border-radius: 10px;
	display: grid;
	place-items: center;
	cursor: pointer;
	transition: all 0.5s;
}

.arrow svg {
	color: #6253e8;
}

.arrow {
	background-color: #f5f5f5;
	transition: all 0.5s;
}

@media only screen and (max-width: 600px) {
	.btn-wrapper {
		flex-flow: wrap;
		flex-direction: column;
	}
	
	.btn {
		font-size: 1.15rem;
		width: auto;
		justify-content: center;
	}
	
	.cv {
		margin-left: 0;
		margin-top: 1rem;
	}
	
	.App h2 {
		font-size: calc(1vw + 1.75rem);
	}
}
